<template>
	<div ref="el" style="width: 200px;height: 200px;"></div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
	name: 'WsQrcode',
	props: {
		src: String
	},
	data() {
		return {};
	},
	mounted() {
		this.buildQRCode();
	},
	watch: {
		src() {
			this.buildQRCode();
		}
	},
	methods: {
		buildQRCode: function() {
			if (this.src) {
				this.$nextTick(function() {
					new QRCode(this.$refs.el, {
						text: this.src,
						width: 200,
						height: 200,
						colorDark: '#333333', //二维码颜色
						colorLight: '#ffffff', //二维码背景色
						correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
					});
				});
			}
		}
	}
};
</script>
