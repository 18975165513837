<template>
    <a-space direction="vertical">
        <a-card title="查询条件">
            <a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
                <a-row>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="文章标题">
                            <a-input v-model="query.title" placeholder="请输入文章标题"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="状态">
                            <a-select style="width: 100%" v-model="query.status" placeholder="请选择状态">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option :value="1">上架</a-select-option>
                                <a-select-option :value="2">下架</a-select-option>
                                <a-select-option :value="3">草稿箱</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item label="栏目">
                            <a-cascader :options="categories" placeholder="请选择栏目" change-on-select v-model="query.category_ids" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                        <a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
                            <a-space>
                                <a-button type="primary" html-type="submit">查询</a-button>
                                <a-button @click="onReset">重置</a-button>
                            </a-space>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-card>
        <a-card>
            <a-space slot="title" :size="24">
                <span>文章列表</span>
                <a-popconfirm title="确认要更新公众号菜单吗？" @confirm="onUpdateMenu">
                    <a-button type="danger">更新公众号菜单</a-button>
                </a-popconfirm>
            </a-space>
            <a-space slot="extra">
                <!-- <a-popconfirm title="确认要更新公众号菜单吗？" @confirm="onUpdateMenu"><a-button type="primary">更新公众号菜单</a-button></a-popconfirm> -->
                <a-button type="primary" @click="$router.push('/articles/create')">新增</a-button>
            </a-space>
            <div class="statistics">
                <span>文章总数：{{ lists.total }}</span>
            </div>

            <a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
                <a-table-column key="id" title="ID" data-index="id" />
                <a-table-column key="title" title="文章标题" data-index="title"></a-table-column>
                <a-table-column key="banner" title="封面图" data-index="banner">
                    <template slot-scope="banner">
                        <a-avatar shape="square" :size="64" :src="banner" />
                    </template>
                </a-table-column>
                <a-table-column key="categories" title="栏目" data-index="categories">
                    <template slot-scope="status, record">
                        <!-- <a-button
							type="link"
							target="_blank"
							:href="`${baseUrl}/pages/news/lists?firstMenu=${record.firstMenu || ''}&twoMenu=${record.twoMenu || ''}&threeMenu=${record.threeMenu || ''}`"
						> -->
                        <span>{{ record.firstMenuName }}</span>
                        <span v-if="record.twoMenuName">/ {{ record.twoMenuName }}</span>
                        <span v-if="record.threeMenuName">/ {{ record.threeMenuName }}</span>
                        <!-- </a-button> -->
                    </template>
                </a-table-column>
                <a-table-column key="status" title="状态" data-index="status">
                    <template slot-scope="status, record">
                        <a-tag color="blue" v-if="status == 1">{{ record.statusStr }}</a-tag>
                        <a-tag color="yellow" v-else-if="status == 2">{{ record.statusStr }}</a-tag>
                        <a-tag color="orange" v-else-if="status == 3">{{ record.statusStr }}</a-tag>
                    </template>
                </a-table-column>
                <a-table-column key="audioTitle" title="音频标题" data-index="audioTitle" />
                <a-table-column key="ctime" title="创建时间" data-index="ctime" />
                <a-table-column key="previewUrl" title="操作" align="center" data-index="previewUrl">
                    <template slot-scope="text, record">
                        <a-popover trigger="click">
                            <div slot="content">
                                <ws-qrcode :src="record.previewUrl"></ws-qrcode>
                            </div>
                            <a-button type="link">预览二维码</a-button>
                        </a-popover>
                    </template>
                </a-table-column>
                <a-table-column key="action" title="操作" align="center" fixed="right">
                    <template slot-scope="text, record">
                        <a-space :direction="docWidth>440?'horizontal':'vertical'">
                            <a-button type="link" target="_blank" :href="`${baseUrl}/pages/news/preview?id=${record.id}&token=${token}`">预览</a-button>
                            <a-button size="small" type="primary" @click="$router.push({ name: 'articles-edit', params: { id: record.id } })">编辑</a-button>
                            <a-popconfirm title="确认要删除吗？" @confirm="onDelete(record)">
                                <a-button size="small" type="danger">删除</a-button>
                            </a-popconfirm>
                        </a-space>
                    </template>
                </a-table-column>
            </a-table>
        </a-card>
    </a-space>
</template>

<script>
import moment from "moment";
import VueViewer from "vue-viewerjs";
import { mapState } from "vuex";
import { parseQuery, numberFormat } from "@/utils";
import WsQrcode from "@/components/WsQrcode";
const formatter = "YYYY-MM-DD";
const DEFAULT_QUERY = {
    page: 1,
    size: 10,
    status: undefined,
    title: undefined,
    category_ids: [],
};
const formatCategories = (categories) => {
    return categories.map((item) => {
        if (Array.isArray(item.children)) {
            if (item.children.length === 0) {
                delete item.children;
            } else {
                item.children = formatCategories(item.children);
            }
        }
        return item;
    });
};
export default {
    components: { VueViewer, WsQrcode },
    data() {
        return {
            query: Object.assign({}, DEFAULT_QUERY),
            lists: {
                data: [],
                current_page: 1,
                per_page: 10,
                total: 0,
            },
            categories: [],
            baseUrl: location.origin.replace("admin", "h5"),
        };
    },
    computed: mapState(["token"]),
    mounted() {
        const { firstMenu, twoMenu, threeMenu } = this.$route.query;
        const category_ids = [];
        firstMenu && category_ids.push(+firstMenu);
        twoMenu && category_ids.push(+twoMenu);
        threeMenu && category_ids.push(+firstMenu);
        Object.assign(this.query, { category_ids });
        this.loadCategories();
        this.getArticles();
    },
    methods: {
        async loadCategories() {
            const response = await this.$api.get("/wechat_menu_list");
            if (response && response.code == 200) {
                this.categories = formatCategories(response.data || []);
            }
        },
        async getArticles() {
            const { category_ids, ...data } = this.query;
            const [firstMenu, twoMenu, threeMenu] = category_ids;
            const response = await this.$api.get("/wechat_article_list", {
                params: Object.assign(data, { firstMenu, twoMenu, threeMenu }),
            });
            if (response && response.code == 200) {
                response.data.data.forEach((item) => {
                    "https://qr.api.cli.im/newqr/create?data=https%253A%252F%252Fadmin.youmtitaogmh.com%252F%2523%252Farticles&level=H&transparent=false&bgcolor=%23ffffff&forecolor=%23000000&blockpixel=12&marginblock=1&logourl=https%3A%2F%2Fncstatic.clewm.net%2Frsrc%2F2022%2F0407%2F23%2F430f5ddedb996ca6783f0706abe1593f.png&logoshape=ellipse&size=260&logo_shadow=0&embed_text_fontfamily=simhei.ttc&eye_use_fore=1&qrcode_eyes=null&outcolor=%23000000&incolor=%23000000&body_type=0&qr_rotate=0&text=&fontfamily=simsun.ttc&fontsize=30&fontcolor=%23000000&logo_pos=0&kid=cliim&key=f9b503c9dd098b21e2921f0c9168dab3";
                    item.previewUrl = `${this.baseUrl}/pages/news/preview?id=${item.id}&token=${this.token}`;
                    item.qrcodeUrl =
                        "https://www.lofter.com/genBitmaxImage?url=" +
                        encodeURIComponent(item.previewUrl);
                });
                this.lists = Object.assign(this.lists, response.data);
                location.hash =
                    this.$route.path +
                    "?" +
                    parseQuery({ firstMenu, twoMenu, threeMenu });
            }
        },
        async onPageChange(pagination) {
            this.query.page = pagination.current;
            this.query.size = pagination.pageSize;
            this.getArticles();
        },
        async onSearch() {
            this.query.page = 1;
            this.getArticles();
        },
        async onReset() {
            this.query = Object.assign({}, DEFAULT_QUERY);
            this.getArticles();
        },
        async onUpdateMenu() {
            const response = await this.$api.get("/set_twl_menu");
            if (response && response.code == 200) {
                this.$message.success("操作成功!");
            } else {
                this.$message.error(response.msg);
            }
        },
        async onDelete(record) {
            const response = await this.$api.get(
                `/wechat_article_del/${record.id}`
            );
            if (response && response.code == 200) {
                this.getArticles();
                this.$message.success("操作成功!");
            } else {
                this.$message.error(response.msg);
            }
        },
    },
};
</script>
